<template>
  <div class="container home3">
    <div class="row">
      <maintop1 ref="newmesstop" />
      <maintop2 />
      <div
        :class="
          flag_nav ? 'main_fix_offset col-xl-10' : 'main_fix_width col-xl-12'
        "
        class="header_mt"
        style="padding-right: calc(var(--bs-gutter-x) * 0.5 - 6px)"
      >
        <div id="root">
          <div id="cricket-page-wrapper" class="sport-page-wrapper">
            <div id="loading">
              <img
                src="@/assets/img/main/ben-redblock-loading.gif"
                style="max-width: 75%"
              />
            </div>
            <div class="sport-widgets">
              <div
                id="sport-root"
                class="h-100"
                data-ck-props='{"theme":{"pbgc":"000","sbgc":"1a1a1a","pc":"D2B79E","sc":"333","tc":"333","tpc":"fff","tsc":"999","qlbc":"333","primaryColorLinearGradientParams":{"deg":"","color1":"F4E7D6","opacity1":"","color2":"D2B79E","opacity2":"","color3":"","opacity3":""},"quickLink":{"sponsor":true},"burger":false,"eventList":{"statementLink":true}},"displayHeader":false,"displayPCLeftSidebar":false,"displayPCNavbar":false,"displayPCNavHome":false,"displayPCNavSearch":false,"autoBetSuffix":"https://sprodm.uni247.xyz/"}'
              ></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Cookies from "js-cookie";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import Footer from "./Home/Footer.vue";
import "@/assets/style/sport.scss";

export default {
  name: "main_sport",
  data() {
    return {
      code: Cookies.get("code"),
      TOKEN:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiODg1YWI5NDQtNzhmMS00NmZkLTgzNTItNDc1MTJlM2VhOTY3IiwicGxheWVyX2lkIjoiaW5kaWFfMy4wX2Zvcl9kaXNwbGF5IiwibWVyY2hhbnRfY29kZSI6ImJhY2tvZmZpY2UtZDllMzIiLCJpc3N1ZWRfYXQiOiIyMDIyLTEwLTI4VDA4OjA5OjUzLjU0ODE0ODcyNloiLCJleHBpcmVzX2F0IjoiMjEyMi0xMC0yOFQwMDowMDowMC4wMDAwMDE2ODdaIn0.WEL6t_UEf8KglL-p_OLQe5xsHhDgaDelQMYZniCkcGk",
    };
  },
  created() {
    var scripts = document.getElementsByTagName("script");
    if (scripts.length) {
      for (var i = 0; i < scripts.length; i++) {
        if (
          scripts[i].src.includes("http") &&
          !scripts[i].src.includes(location.host)
        ) {
          window.location.reload();
        }
      }
    }
    if (this.code != null) {
      let param = {
        action: "stage_balance",
        body: {
          uid: localStorage.getItem("uid"),
          ip: localStorage.getItem("ip"),
          session_code: Cookies.get("code"),
          device: "web",
          gametype: "SPORT",
          gamekind: "sport",
          gameid: "",
          mode: "sport",
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        let script = document.createElement("script");
        script.src = "https://cdn.seondf.com/js/v5/agent.js";
        document.body.appendChild(script);
        script = document.createElement("script");
        script.innerHTML =
          '(function(n,t,i,r,u,f,e,o,s){n[u]||(s=n[u]=function(){(n[u].q=n[u].q||[]).push(arguments)},e=t.createElement(i),o=t.getElementsByTagName(i)[0],e.async=1,e.src=r,o.parentNode.insertBefore(e,o))})(window,document,"script",`https://sprodm.uni247.xyz/international/international-pro.js?v="${Date.now()}"`,"CRIC");CRIC({token:"' +
          res +
          '"});';
        document.body.appendChild(script);
      });
    } else {
      let param = {
        action: "sport_guest",
        body: {
          gametype: "SPORT",
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        let script = document.createElement("script");
        script.src = "https://cdn.seondf.com/js/v5/agent.js";
        document.body.appendChild(script);
        script = document.createElement("script");
        script.innerHTML =
          '(function(n,t,i,r,u,f,e,o,s){n[u]||(s=n[u]=function(){(n[u].q=n[u].q||[]).push(arguments)},e=t.createElement(i),o=t.getElementsByTagName(i)[0],e.async=1,e.src=r,o.parentNode.insertBefore(e,o))})(window,document,"script",`https://sprodm.uni247.xyz/international/international-pro.js?v="${Date.now()}"`,"CRIC");CRIC({token:"' +
          res +
          '"});';
        document.body.appendChild(script);
      });
      // let script = document.createElement("script");
      // script.src = "https://cdn.seondf.com/js/v5/agent.js";
      // document.body.appendChild(script);
      // script = document.createElement("script");
      // script.innerHTML =
      //   '(function(n,t,i,r,u,f,e,o,s){n[u]||(s=n[u]=function(){(n[u].q=n[u].q||[]).push(arguments)},e=t.createElement(i),o=t.getElementsByTagName(i)[0],e.async=1,e.src=r,o.parentNode.insertBefore(e,o))})(window,document,"script",`https://sprodm.uni247.xyz/international/international-pro.js?v="${Date.now()}"`,"CRIC");CRIC({token:"' +
      //   this.TOKEN +
      //   '"});';
      // document.body.appendChild(script);
    }
  },
  components: {
    Footer,
    maintop1,
    maintop2,
  },
  computed: {
    ...mapGetters(["flag_nav"]),
  },
  watch: {},
};
</script>
